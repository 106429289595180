import withAuthentication from './withAuthentication';
import withAuthorization from './withAuthorization';
import withEmailVerification from './withEmailVerification';

const isValidUser = (authUser) => !!authUser;

const hasAdminAccess = (authUser) => authUser && authUser.claims && authUser.claims.admin;

export {
  withAuthentication,
  withAuthorization,
  withEmailVerification,
  isValidUser,
  hasAdminAccess
};
