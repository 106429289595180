export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const USER_DETAILS = '/admin/users/:userId';
export const USER_REPORT = '/admin/report/:userId';
export const TIMESHEET = '/timesheet';
export const TIMESHEET_ENTRY = '/timesheet/:year/:month/:day';
