import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import differenceInMinutes from 'date-fns/differenceInMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';

import { withFirebase } from '../Firebase';

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    }

    confirmationEmailLastSent(authUser) {
      return authUser && authUser.confirmationEmailSent && authUser.confirmationEmailSent.toDate
        ? authUser.confirmationEmailSent.toDate()
        : undefined;
    }

    emailConfirmationSentMessage(lastSendTime) {
      return (
        <Typography>
          A confirmation message was sent to you {formatDistanceToNow(lastSendTime)} ago.
          Please check your emails (including your spam folder) for this message,
          and click the link in that message to verify your email address.
        </Typography>
      );
    }

    render() {
      const confirmationEmailLastSent = this.confirmationEmailLastSent(this.props.authUser);
      const confirmationEmailSentRecently = confirmationEmailLastSent && differenceInMinutes(new Date(), confirmationEmailLastSent) < 5;

      const emailConfirmationMessage = confirmationEmailSentRecently
        ? this.emailConfirmationSentMessage(confirmationEmailLastSent) : "";

      return needsEmailVerification(this.props.authUser) ? (
        <Paper>
          <Card>
            <CardContent>
              <Alert severity="warning" elevation={2} variant="filled">
                Email Address Verification Required
              </Alert>
              {emailConfirmationMessage}
              <Typography>
                Please click the button below to send a new confirmation message to your registered
                Email address.  Note that this will invalidate the link in any confirmation messages you
                might already have received.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" type="button"
                onClick={this.onSendEmailVerification}
                disabled={this.state.isSent || confirmationEmailSentRecently}
              >
              Send confirmation Email
              </Button>
            </CardActions>
          </Card>
        </Paper>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withFirebase,
    connect(mapStateToProps),
  )(WithEmailVerification);
};

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

export default withEmailVerification;
