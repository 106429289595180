import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { styled  } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SpacedAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const PasswordForgetPage = () => (
  <Paper>
      <PasswordForgetForm />
  </Paper>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Card>
        <form onSubmit={this.onSubmit}>
          <CardHeader title="Forgotten Password" />
          <CardContent>
            <TextField id="email" name="email" label="Email Address" type="text" value={this.state.email} variant="outlined" fullWidth required onChange={this.onChange} />

            {error && <SpacedAlert severity="warning" elevation={2}  variant="filled">{error.message}</SpacedAlert>}
          </CardContent>
          <CardActions>
            <span style={{flexGrow: 1}} />
            <Button variant="contained" color="primary" disabled={isInvalid} type="submit">
              Reset My Password
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

const PasswordForgetLink = () => (
  <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
);

const PasswordForgetButton = () => (
  <Button component={Link} to={ROUTES.PASSWORD_FORGET} variant="outlined">Forgot Password?</Button>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(withFirebase)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink, PasswordForgetButton };
