import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification, isValidUser } from '../Session';
import TimeSheetEntry from './TimeSheetEntry';
import TimeSheetSummary from './TimeSheetSummary';
import * as ROUTES from '../../constants/routes';

const TimeSheet = () => (
  <Switch>
    <Route exact path={ROUTES.TIMESHEET_ENTRY} component={TimeSheetEntry} />
    <Route component={TimeSheetSummary} />
  </Switch>
);

export default compose(
  withEmailVerification,
  withAuthorization(isValidUser),
)(TimeSheet);
