import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Skeleton from '@material-ui/lab/Skeleton';

import TimeSheetList from '../TimeSheet/TimeSheetList';
import { withFirebase } from '../Firebase';

class UserReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingUser: false,
      loadingTimesheet: false,
    };
  }

  componentDidMount() {
    const userId = this.props.match.params.userId;
    if (!this.props.user) {
      this.setState({ loadingUser: true });

      this.unsubscribeUser = this.props.firebase
        .user(userId)
        .onSnapshot(snapshot => {
          this.props.onSetUser(
            {...snapshot.data(), uid: snapshot.id},
            this.props.match.params.userId,
          );

          this.setState({ loadingUser: false });
        });
    }

    if (!this.props.userTimesheets[userId]) {
      this.unsubscribeTimesheets = this.props.firebase
        .timesheets(userId)
        .orderBy("timeSheetDate", "desc")
        .onSnapshot(snapshot => {
          let timesheets = [];
          snapshot.forEach(doc =>
            timesheets.push({...doc.data(), uid: doc.id})
          );
          this.props.onSetUserTimeSheets(userId, timesheets.reverse());
        });
    }
  }

  componentWillUnmount() {
    this.unsubscribeUser && this.unsubscribeUser();
    this.unsubscribeTimesheets && this.unsubscribeTimesheets();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.props.user.email);
  };

  render() {
    const { user, userTimesheets } = this.props;
    const { loadingTimesheet } = this.state;

    const timesheets = user ? userTimesheets[user.uid] : [];

    return (
        <Box py={2}>
          <Card>
            <CardHeader title={"User Report - " + (user ? user.username : "loading...")} />

            <CardContent>
              {loadingTimesheet && <Skeleton animation="wave" width="100%"></Skeleton>}

              <TimeSheetList timesheets={timesheets} />
            </CardContent>
          </Card>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.userState.users.find(user => user.uid === props.match.params.userId),
  userTimesheets: state.timeSheetState.userTimesheets,
});

const mapDispatchToProps = dispatch => ({
  onSetUser: (user, uid) => dispatch({ type: 'USER_SET', user, uid }),
  onSetUserTimeSheets: (userId, timesheets) => dispatch({ type: 'USER_TIMESHEETS_SET', userId, timesheets }),
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserReport);
