import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

import * as ROUTES from '../../constants/routes';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.analytics();

    /* Helper */
    this.fieldValue = app.firestore.FieldValue;

    this.auth = app.auth();
    this.db = app.firestore();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doProfileUpdate = (profileData) =>
    this.auth.currentUser.updateProfile(profileData)
      .then(() => this.auth.currentUser.reload());

  doSendEmailVerification = () => {
    return this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT + ROUTES.HOME,
    })
    .then(() => {
      return this.user(this.auth.currentUser.uid)
        .set({confirmationEmailSent: this.fieldValue.serverTimestamp()}, { merge: true });
    })
    .then(() => this.auth.currentUser.reload());
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        const authToken = authUser.getIdTokenResult(true);
        const firestoreDoc = this.user(authUser.uid).get();

        Promise.all([authToken, firestoreDoc])
          .then(([auth, snapshot]) => {
            const dbUser = snapshot.data();

            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              claims: auth.claims,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** USER API ***

  user = uid => this.db.collection('users').doc(uid);

  users = () => this.db.collection('users');

  // *** Message API ***

  message = uid => this.db.collection('messages').doc(uid);

  messages = () => this.db.collection('messages');

  // *** TimeSheet API ***

  timesheet = uid => this.db.collection('timesheets').doc(uid);

  timesheets = userId => {
    const collection = this.db.collection('timesheets');

    return userId ? collection.where("userId", "==", userId) : collection;
  }
}

export default Firebase;
