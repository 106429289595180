import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Gravatar from 'react-gravatar';

import Box from '@material-ui/core/List';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';

import { withFirebase } from '../Firebase';
import { requestUsers } from '../../actions/user';
import * as ROUTES from '../../constants/routes';

class UserList extends Component {
  componentDidMount() {
    this.unsubscribe = requestUsers(this.props.firebase, this.props.dispatch);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { users, usersLoaded } = this.props;

    return (
      <Box>
        <Typography variant="h6">Users</Typography>
        {!usersLoaded && <div>Loading ...</div>}
        <List>
          {users.map(user => UserListItem(user))}
        </List>
      </Box>
    );
  }
}

const UserListItem = (user) => (
  <ListItem key={user.uid}>
    <ListItemAvatar>
      <Avatar alt={user.username}><Gravatar email={user.email} /></Avatar>
    </ListItemAvatar>
    <ListItemText primary={user.username} secondary={user.email} />
    <ListItemSecondaryAction>
      <IconButton component={Link} to={{
        pathname: ROUTES.USER_REPORT.replace(":userId", user.uid)
      }}>
        <ListIcon />
      </IconButton>
      <IconButton component={Link} to={{
        pathname: ROUTES.USER_DETAILS.replace(":userId", user.uid)
      }}>
        <EditIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
)

const mapStateToProps = state => ({
  users: state.userState.users.sort((a, b) => a.username > b.username ? 1 : -1),
  usersLoaded: state.userState.usersLoaded
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps
  ),
)(UserList);
