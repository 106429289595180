import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, hasAdminAccess } from '../Session';

class MessageSender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().add({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  render() {
    const { text } = this.state;

    return (
      <form
        onSubmit={event =>
          this.onCreateMessage(event, this.props.authUser)
        }
      >
        <input
          type="text"
          value={text}
          onChange={this.onChangeText}
        />
        <button type="submit">Send</button>
      </form>
    );
  }
}

export default compose(
  withFirebase,
  withAuthorization(hasAdminAccess, false),
)(MessageSender);
