import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { withAuthorization, withEmailVerification, isValidUser } from '../Session';
import { withFirebase } from '../Firebase';
import Messages from '../Messages';
import TimeSheet from '../TimeSheet';
import { requestUsers } from '../../actions/user';

class HomePage extends Component {
  componentDidMount() {
    this.unsubscribe = requestUsers(this.props.firebase, this.props.dispatch);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { users } = this.props;

    return (
      <Paper>
        <Box mt={2} p={2}>
          <Messages users={users} />
        </Box>

        <Box p={2}>
          <TimeSheet />
        </Box>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  users: state.userState.users,
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps
  ),
  withEmailVerification,
  withAuthorization(isValidUser),
)(HomePage);
