import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { withFirebase } from '../Firebase';

const SpacedAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const SpacedTextField = styled(TextField)(({theme}) => ({
  marginTop: theme.spacing(1),
}));

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Card>
        <form onSubmit={this.onSubmit}>
          <CardHeader title="Change Password" />
          <CardContent>
            <TextField id="password-one" name="passwordOne" label="New Password" type="password" value={passwordOne} autoComplete="new-password" variant="outlined" fullWidth required onChange={this.onChange} />
            <SpacedTextField id="password-two" name="passwordTwo" label="Confirm New Password" type="password" value={passwordTwo} autoComplete="new-password" variant="outlined" fullWidth required onChange={this.onChange} />

            {error && <SpacedAlert severity="warning" elevation={2}  variant="filled">{error.message}</SpacedAlert>}
          </CardContent>
          <CardActions>
            <span style={{flexGrow: 1}} />
            <Button variant="contained" color="primary" disabled={isInvalid} type="submit">
              Change My Password
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withFirebase(PasswordChangeForm);
