import React, { Component } from 'react';
import ReactCalendar from 'react-calendar';
import { startOfToday, isWeekend, isEqual } from 'date-fns'

import Skeleton from '@material-ui/lab/Skeleton';

import 'react-calendar/dist/Calendar.css';

class Calendar extends Component {
  isSameDate(timeSheetDate, date) {
    return timeSheetDate && timeSheetDate.toDate && isEqual(timeSheetDate.toDate(), date);
  }

  calendarTileClassName(date, view) {
    if (!this.props.timesheets || !this.props.timesheets.length) return;

    if (view === 'month') {
      const timesheet = this.props.timesheets
        .find(timesheet => this.isSameDate(timesheet.timeSheetDate, date))
      if (timesheet) {
        return "filled" + (timesheet.overNightWork ? " overnight" : "");
      }
      if (date < startOfToday() && !isWeekend(date)) return "overdue"
    }
  }

  render() {
    return this.props.timeSheetsLoaded ? (
      <ReactCalendar
        onClickDay={this.props.onDateSelect}
        tileClassName={({ date, view}) => this.calendarTileClassName(date, view)}
      />
    ) : (
      <Skeleton animation="wave"><ReactCalendar /></Skeleton>
    );
  }
}

export default Calendar;
