import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';

import SignOutMenuItem from '../SignOut';
import { withAuthentication, hasAdminAccess } from '../Session';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navigation = ({ authUser }) => (
  <AppBar position="static">
    {authUser ? (
      <NavigationAuth authUser={authUser} />
    ) : (
      <NavigationNonAuth />
    )}
  </AppBar>
);

const NavigationAuth = ({ authUser }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar>
      <IconButton component={Link} to={ROUTES.HOME} color="inherit">
        <HomeIcon />
      </IconButton>
      <Typography className={classes.title}>Time Tracker</Typography>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        keepMounted transformOrigin={{vertical: 'top', horizontal: 'right'}} open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={ROUTES.ACCOUNT} onClick={handleClose}>Account</MenuItem>
        {hasAdminAccess(authUser) && (
          <MenuItem component={Link} to={ROUTES.ADMIN} onClick={handleClose}>Admin</MenuItem>
        )}
        <SignOutMenuItem onClick={handleClose} />
      </Menu>
    </Toolbar>
  );
}

const NavigationNonAuth = () => {
  const classes = useStyles();

  return (
    <Toolbar>
      <IconButton component={Link} to={ROUTES.LANDING} color="inherit">
        <HomeIcon />
      </IconButton>
      <Typography className={classes.title}>Time Tracker</Typography>
      <Button component={Link} to={ROUTES.SIGN_IN} color="inherit">Sign In</Button>
    </Toolbar>
  )
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withAuthentication,
  connect(
    mapStateToProps,
  ),
)(Navigation);
