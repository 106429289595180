import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { withAuthorization, withEmailVerification, isValidUser } from '../Session';
import Calendar from '../Calendar';
import TimeSheetList from './TimeSheetList';
import * as ROUTES from '../../constants/routes';

class TimeSheetSummary extends Component {
  componentDidMount() {
    this.onListenForTimeSheets();
  }

  componentDidUpdate(props) {
    if (props.authUser !== this.props.authUser) {
      this.onListenForTimeSheets();
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onListenForTimeSheets = () => {
    if (this.props.authUser) {
      const userId = this.props.authUser.uid;
      this.unsubscribe = this.props.firebase
        .timesheets(userId)
        .orderBy("timeSheetDate", "desc")
        .limit(7)
        .onSnapshot(snapshot => {
            let timesheets = [];
            snapshot.forEach(doc =>
              timesheets.push({...doc.data(), uid: doc.id})
            );
          this.props.onSetTimeSheets(timesheets.reverse());
        });
    }
  };

  navigateToTimesheet(chosenDate) {
    const year = chosenDate.getFullYear();
    const month = chosenDate.getMonth() + 1;
    const day = chosenDate.getDate();

    const target = ROUTES.TIMESHEET_ENTRY.replace(":year", year).replace(":month", month).replace(":day", day);

    this.props.history.push(target);
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardHeader title="Please select a date from the calendar to enter your timesheet" />

          <CardContent>
            <Calendar
              timesheets={this.props.timesheets}
              timeSheetsLoaded={this.props.timeSheetsLoaded}
              onDateSelect={(value, event) => this.navigateToTimesheet(value)}
            />
          </CardContent>
        </Card>

        <TimeSheetList timesheets={this.props.timesheets} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  timesheets: state.timeSheetState.timesheets,
  timeSheetsLoaded: state.timeSheetState.timeSheetsLoaded,
});

const mapDispatchToProps = dispatch => ({
  onSetTimeSheets: timesheets =>
    dispatch({ type: 'TIMESHEETS_SET', timesheets }),
});

export default compose(
  withEmailVerification,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withAuthorization(isValidUser),
)(TimeSheetSummary);
