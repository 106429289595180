import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withAuthentication } from '../Session';

import * as ROUTES from '../../constants/routes';

const Landing = ({authUser}) => (
  <Paper>
    <Box mt={2} p={2}>
      <Typography variant="h4">Prient Time Tracker</Typography>
      {authUser ? (
        <React.Fragment>
          <p>Welcome back {authUser.username}. Please wait a moment while you are redirected to the Home page.</p>
          <Redirect to={ROUTES.HOME} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>Welcome to the Prient Technologies Time Tracker application</p>
          <p>Please click <Link to={ROUTES.SIGN_IN}>Sign In</Link> to sign in to your account.</p>
          <p>If you do not already have an account, you can click <Link to={ROUTES.SIGN_UP}>Sign Up</Link> to create one</p>
        </React.Fragment>
      )}
    </Box>
  </Paper>
)

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withAuthentication,
  connect(
    mapStateToProps,
  ),
)(Landing);
