const INITIAL_STATE = {
  timesheets: [],
  timeSheetsLoaded: false,
  userTimesheets: {},
};

const applySetTimeSheets = (state, action) => ({
  ...state,
  timesheets: action.timesheets,
  timeSheetsLoaded: true
});

const applySetUserTimeSheets = (state, action) => ({
  ...state,
  userTimesheets: {
    ...state.userTimesheets,
    [action.userId]: action.timesheets,
  }
});

function timesheetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TIMESHEETS_SET': {
      return applySetTimeSheets(state, action);
    }
    case 'USER_TIMESHEETS_SET': {
      return applySetUserTimeSheets(state, action);
    }
    default:
      return state;
  }
}

export default timesheetReducer;
