import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import GitInfo from 'react-git-info/macro';

import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';
import 'fontsource-roboto';
import store from './store';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import * as serviceWorker from './serviceWorker';

const config = {
  sentryDSN: process.env.REACT_APP_SENTRY_KEY,
  version: (process.env.NODE_ENV === 'production') ? process.env.npm_package_version : GitInfo().commit.shortHash,
};

config.sentryDSN && Sentry.init({
  dsn: config.sentryDSN,
  environment: process.env.NODE_ENV,
  release: 'time-tracker@' + config.version,
});

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <CssBaseline />
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
