import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';

import { withFirebase } from '../Firebase';
import MessageList from './MessageList';
import MessageSender from './MessageSender';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.messages.length) {
      this.setState({ loading: true });
    }

    this.onListenForMessages();
  }

  componentDidUpdate(props) {
    if (props.limit !== this.props.limit) {
      this.onListenForMessages();
    }
  }

  onListenForMessages = () => {
    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy('createdAt', 'desc')
      .limit(this.props.limit)
      .onSnapshot(snapshot => {
        let messages = [];
        if (snapshot.size) {
          snapshot.forEach(doc =>
            messages.push({ ...doc.data(), uid: doc.id }),
          );
        }
        this.props.onSetMessages(messages);

        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onEditMessage = (message, text) => {
    this.props.firebase.message(message.uid).set({
      ...message,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).remove();
  };

  onNextPage = () => {
    this.props.onSetMessagesLimit(this.props.limit + 5);
  };

  findUser(userId) {
    const { users } = this.props;

    const user = users && users.find(user => user.uid === userId);
    return user || { userId };
  }

  render() {
    const { messages } = this.props;
    const { loading } = this.state;

    return (
      <Card>
        <CardContent>
          <Typography variant="subtitle1">Messages from the Admin Team</Typography>
          {loading && <Skeleton width="100%" animation="wave"><Alert /></Skeleton>}

          {messages && (
            <MessageList
              authUser={this.props.authUser}
              messages={messages.map(message => ({
                ...message,
                user: this.findUser(message.userId),
              }))}
              onEditMessage={this.onEditMessage}
              onRemoveMessage={this.onRemoveMessage}
            />
          )}

          {!loading && (!messages || messages.length === 0) && <Alert severity="info" elevation={2} variant="outlined">There are no messages to display</Alert>}
        </CardContent>
        <CardActions>
          <MessageSender />
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  messages: Object.keys(state.messageState.messages || {}).map(
    key => ({
      ...state.messageState.messages[key],
      uid: key,
    }),
  ),
  limit: state.messageState.limit,
});

const mapDispatchToProps = dispatch => ({
  onSetMessages: messages =>
    dispatch({ type: 'MESSAGES_SET', messages }),
  onSetMessagesLimit: limit =>
    dispatch({ type: 'MESSAGES_LIMIT_SET', limit }),
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Messages);
