import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles, styled  } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(1)
    }
});

const SpacedAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const SignUpPage = () => (
  <Paper>
    <Card>
      <SignUpForm />
    </Card>
  </Paper>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email.trim(), passwordOne.trim())
      .then(authUser => this.props.firebase.doProfileUpdate({displayName: username}).then(() => authUser))
      .then(authUser => {
        return this.props.firebase
          .user(authUser.user.uid)
          .set({username, email}, { merge: true })
          .then(() => authUser);
      })
      .then(authUser => {
        return this.props.firebase.doSendEmailVerification().then(() => authUser);
      })
      .then(authUser => {
        return this.props.firebase
          .user(authUser.user.uid)
          .set({confirmationEmailSent: this.props.firebase.fieldValue.serverTimestamp()}, { merge: true });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    const { classes } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <form onSubmit={this.onSubmit}>
        <CardContent>
          <Typography variant="h5">Sign Up</Typography>
          <TextField id="username" name="username" label="Full Name" type="text" autoComplete="name" value={username} variant="outlined" className={classes.margin} fullWidth required onChange={this.onChange} />
          <TextField id="email" name="email" label="Email Address" type="text" autoComplete="email" value={email} variant="outlined" className={classes.margin} fullWidth required onChange={this.onChange} />
          <TextField id="password-one" name="passwordOne" label="Password" type="password" autoComplete="new-password" value={passwordOne} variant="outlined" className={classes.margin} fullWidth required onChange={this.onChange} />
          <TextField id="password-two" name="passwordTwo" label="Confirm Password" type="password" autoComplete="new-password" value={passwordTwo} variant="outlined" className={classes.margin} fullWidth required onChange={this.onChange} />

          {error && <SpacedAlert severity="warning" variant="filled">{error.message}</SpacedAlert>}
        </CardContent>
        <CardActions>
          <span style={{flexGrow: 1}} />
          <Button variant="contained" elevation={2} color="primary" disabled={isInvalid} type="submit">
            Sign Up
          </Button>
        </CardActions>
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpButton = () => (
  <Button variant="outlined" color="secondary" component={Link} to={ROUTES.SIGN_UP}>
    Sign Up
  </Button>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles),
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink, SignUpButton };
