import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles, styled  } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { SignUpButton } from '../SignUp';
import { PasswordForgetButton } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(1)
    }
});

const SpacedAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const SignInPage = () => (
  <Paper>
    <Card>
      <SignInForm />
    </Card>
  </Paper>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        let updatedData = { email };
        if (authUser.user.displayName) updatedData['username'] = authUser.user.displayName;
        return this.props.firebase
          .user(authUser.user.uid)
          .set(updatedData, { merge: true })
          .then(() => authUser);
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const { classes } = this.props;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <CardContent>
          <Typography variant="h5">Sign In</Typography>
          <TextField className={classes.margin} id="email" name="email" label="Email Address" type="text" value={email} variant="outlined" fullWidth required onChange={this.onChange} />
          <TextField className={classes.margin} id="password" name="password" label="Password" type="password" value={password} variant="outlined" fullWidth required onChange={this.onChange} />

          {error && <SpacedAlert severity="warning" variant="filled">{error.message}</SpacedAlert>}
        </CardContent>
        <CardActions>
          <PasswordForgetButton />
          <SignUpButton />
          <span style={{flexGrow: 1}} />
          <Button variant="contained" elevation={2} color="primary" disabled={isInvalid} type="submit">
            Sign In
          </Button>
        </CardActions>
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles),
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
