import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import parse from 'date-fns/parse';
import addDays from 'date-fns/addDays';
import isEqual from 'date-fns/isEqual';
import format from 'date-fns/format';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled  } from '@material-ui/core/styles';

import { withFirebase } from '../Firebase';
import { withAuthorization, isValidUser } from '../Session';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  startTime: '',
  endTime: '',
  breakDuration: '',
  travelDuration: '',
  mileage: '',
  jobName: ''
};

const SpacedTextField = styled(TextField)(({theme}) => ({
  margin: theme.spacing(1),
}));

const SpacedFixedTextField = styled(TextField)(({theme}) => ({
  margin: theme.spacing(1),
  width: '25ch'
}));

class TimeSheetEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      loading: true
    };

    if (props.timeSheetsLoaded) {
      this.state = this.loadExistingTimesheet(props.timesheets);
    }
  }

  componentDidMount() {
    this.onListenForTimeSheets();
  }

  componentDidUpdate(props) {
    if (props.timeSheetsLoaded && this.state.loading) {
      this.setState(this.loadExistingTimesheet(props.timesheets));
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  isSameDate(timeSheetDate) {
    return timeSheetDate && timeSheetDate.toDate && isEqual(timeSheetDate.toDate(), this.timeSheetDate());
  }

  loadExistingTimesheet(timesheets) {
    const timesheet = timesheets
      .find(timesheet => this.isSameDate(timesheet.timeSheetDate));
    if (timesheet) {
      return {
        uid: timesheet.uid,
        startTime: format(timesheet.startTime.toDate(), "HH:mm"),
        endTime: format(timesheet.endTime.toDate(), "HH:mm"),
        breakDuration: timesheet.breakDuration,
        travelDuration: timesheet.travelDuration,
        mileage: timesheet.mileage,
        jobName: timesheet.jobName,
        loading: false
      };
    } else {
      return {loading: false, notFound: true};
    }
  }

  onListenForTimeSheets = () => {
    const userId = this.props.authUser.uid;
    this.unsubscribe = this.props.firebase
      .timesheets(userId)
      .onSnapshot(snapshot => {
          let timesheets = [];
          snapshot.forEach(doc =>
            timesheets.push({ ...doc.data(), uid: doc.id })
          );
        this.props.onSetTimeSheets(timesheets);
      });
  };

  timeSheetDate() {
    const { year, month, day } = this.props.match.params;

    return new Date(year, month - 1, day);
  }

  onChangeText = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmitTimeSheet = (event, authUser) => {
    const fieldValue = this.props.firebase.fieldValue;
    const timeSheetDate = this.timeSheetDate();
    const overNightWork = (this.state.endTime < this.state.startTime);
    const startTime = parse(this.state.startTime, "HH:mm", timeSheetDate);
    const endTime = addDays(
      parse(this.state.endTime, "HH:mm", timeSheetDate),
      overNightWork ? 1 : 0
    );

    const data = {
      startTime: startTime,
      endTime: endTime,
      overNightWork: overNightWork,
      breakDuration: this.state.breakDuration,
      travelDuration: this.state.travelDuration,
      ...(this.state.mileage && {mileage: this.state.mileage}),
      jobName: this.state.jobName,
    };

    if (this.state.uid) {
      this.props.firebase.timesheet(this.state.uid)
      .set({ ...data, editedAt: fieldValue.serverTimestamp() }, { merge: true });
    } else {
      this.props.firebase.timesheets().add({
        ...data,
        timeSheetDate: this.timeSheetDate(),
        userId: authUser.uid,
        createdAt: fieldValue.serverTimestamp(),
      });
    }

    this.props.history.push(ROUTES.TIMESHEET);

    event.preventDefault();
  };

  render() {
    const { startTime, endTime, breakDuration, travelDuration, mileage, jobName } = this.state;
    const timeSheetDate = this.timeSheetDate();

    const endLabel = (text) => { return {endAdornment: <InputAdornment position="end">{text}</InputAdornment>}};

    const isInvalid =
      startTime === '' || endTime === '' ||
      breakDuration === '' || travelDuration === '' ||
      jobName === '';

    return this.state.loading ? (<Skeleton width="100%" height="5em" variant="text" animation="wave" />) : (
      <Paper>
        <form
          noValidate
          autoComplete="off"
          onSubmit={event =>
            this.onSubmitTimeSheet(event, this.props.authUser)
          }
        >
          <Card>
            <CardContent>
              <Typography color="textSecondary">
                Enter work details for {timeSheetDate.toDateString()}
              </Typography>
              <div>
                <SpacedTextField required id="job-name" fullWidth name="jobName" label="Job Name" variant="outlined" type="text" value={jobName} onChange={this.onChangeText} />
              </div>
              <div>
                <SpacedFixedTextField required id="start-time" InputLabelProps={{ shrink: true }} name="startTime" label="Start Time" variant="outlined" type="time" inputProps={{ step: (15*60) }} value={startTime} onChange={this.onChangeText} />
                <SpacedFixedTextField required id="end-time" InputLabelProps={{ shrink: true }} name="endTime" label="End Time" variant="outlined" type="time" inputProps={{ step: (15*60) }} value={endTime} onChange={this.onChangeText} />
              </div>
              <div>
                <SpacedFixedTextField required id="break-duration" InputProps={endLabel("mins")} name="breakDuration" label="Break Time" variant="outlined" type="number" inputProps={{ min: 0 }} value={breakDuration} onChange={this.onChangeText} />
                <SpacedFixedTextField required id="break-duration" InputProps={endLabel("mins")} name="travelDuration" label="Travel Time" variant="outlined" type="number"  inputProps={{ min: 0 }} value={travelDuration} onChange={this.onChangeText} />
              </div>
              <div>
                <SpacedFixedTextField id="mileage" InputProps={endLabel("miles")} name="mileage" label="Total Mileage" variant="outlined" type="number" inputProps={{ min: 0 }} value={mileage} onChange={this.onChangeText} />
              </div>
            </CardContent>
            <CardActions>
              <Button type="submit" variant="contained" color="primary" disabled={isInvalid}>Save</Button>
            </CardActions>
          </Card>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  timesheets: state.timeSheetState.timesheets,
  timeSheetsLoaded: state.timeSheetState.timeSheetsLoaded,
});

const mapDispatchToProps = dispatch => ({
  onSetTimeSheets: timesheets =>
    dispatch({ type: 'TIMESHEETS_SET', timesheets }),
});

export default compose(
  withFirebase,
  withRouter,
  withAuthorization(isValidUser),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TimeSheetEntry);
