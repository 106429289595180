import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { withAuthorization, withEmailVerification, hasAdminAccess } from '../Session';
import { UserList, UserItem, UserReport } from '../Users';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => (
  <Paper>
    <Box m={2}>
      <Switch>
        <Route exact path={ROUTES.USER_DETAILS} component={UserItem} />
        <Route exact path={ROUTES.USER_REPORT} component={UserReport} />
        <Route exact path={ROUTES.ADMIN} component={UserList} />
      </Switch>
    </Box>
  </Paper>
);

export default compose(
  withEmailVerification,
  withAuthorization(hasAdminAccess),
)(AdminPage);
