import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { styled  } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';

const SpacedAlert = styled(Alert)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const INITIAL_STATE = {
  authUser: null,
  username: '',
  userId: '',
  error: null,
};

class UserDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      authUser: this.props.authUser,
      username: this.props.authUser.username || "",
      userId: this.props.authUser.uid,
    };
  }

  onSubmit = event => {
    const { authUser, username, userId } = this.state;

    this.props.firebase
      .doProfileUpdate({displayName: username})
      .then(() => {
        return this.props.firebase
          .user(userId)
          .set({ username }, { merge: true });
      })
      .then(() =>
        this.props.onSetAuthUser({...authUser, username: username })
      )
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, error } = this.state;

    return (
      <Card>
        <form onSubmit={this.onSubmit}>
          <CardHeader title="User Details" />
          <CardContent>
            <TextField id="username" name="username" label="Username" type="text" value={username} variant="outlined" fullWidth required onChange={this.onChange} />

            {error && <SpacedAlert severity="warning" elevation={2}  variant="filled">{error.message}</SpacedAlert>}
          </CardContent>
          <CardActions>
            <span style={{flexGrow: 1}} />
            <Button variant="contained" color="primary" type="submit">
              Update Details
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser =>
    dispatch({ type: 'AUTH_USER_SET', authUser }),
});

export default compose(
  withFirebase,
  withAuthentication,
  connect(null, mapDispatchToProps),
)(UserDetailsForm);
