import React from 'react';
import format from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import NightsStayIcon from '@material-ui/icons/NightsStay';

const TimeSheetList = ({timesheets}) => (
  <List dense>
    {timesheets && timesheets.map(timesheet => {
      const primaryText = timesheet.jobName
        + " from " + format(timesheet.startTime.toDate(), "HH:mm")
        + " until " + format(timesheet.endTime.toDate(), "HH:mm")
        + " (" + formatDistanceStrict(timesheet.startTime.toDate(), timesheet.endTime.toDate())
        + (timesheet.overNightWork ? ", overnight" : "") + ")";
      const secondaryText = "Travel: " + timesheet.travelDuration + " mins / "
        + (timesheet.mileage ? timesheet.mileage + " miles" : "no mileage")
        + " Break: " + timesheet.breakDuration + " mins";

      return (
        <ListItem key={"timesheet-" + format(timesheet.timeSheetDate.toDate(), "yyyyMMdd")}>
          <ListItemIcon><strong>{format(timesheet.timeSheetDate.toDate(), "dd/MM")}</strong></ListItemIcon>
          <ListItemText ml={2} primary={primaryText} secondary={secondaryText} />
          {timesheet.overNightWork && (<ListItemSecondaryAction><NightsStayIcon color="disabled"/></ListItemSecondaryAction>)}
        </ListItem>
      )
    })}
  </List>
);

export default TimeSheetList;
