

function requestUsers(firebase, dispatch) {
  return firebase.users()
    .onSnapshot(snapshot => {
      let users = [];

      snapshot.forEach(doc =>
        users.push({ ...doc.data(), uid: doc.id }),
      );

      dispatch({ type: 'USERS_SET', users });
    });
}

export { requestUsers };
