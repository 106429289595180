import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import Skeleton from '@material-ui/lab/Skeleton';

import { withFirebase } from '../Firebase';

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.user) {
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase
        .user(this.props.match.params.userId)
        .onSnapshot(snapshot => {
          this.props.onSetUser(
            {...snapshot.data(), uid: snapshot.id},
            this.props.match.params.userId,
          );

          this.setState({ loading: false });
        });
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.props.user.email);
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    return (
        <Box py={2}>
          <Card>
            <CardHeader
              title={"User Details - " + (user ? user.username : "loading...")}
              subheader={this.props.match.params.userId}
            />

            <CardContent>
              {loading && <Skeleton animation="wave" width="100%"></Skeleton>}

              {user && (
                <List>
                  <ListItem>
                    <ListItemText primary={user.email} secondary="Email" />
                    <ListItemSecondaryAction>
                      <Button variant="outlined" color="secondary" onClick={this.onSendPasswordResetEmail}>
                        Send Password Reset
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}
            </CardContent>
          </Card>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.userState.users.find(user => user.uid === props.match.params.userId),
});

const mapDispatchToProps = dispatch => ({
  onSetUser: (user, uid) => dispatch({ type: 'USER_SET', user, uid }),
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserItem);
