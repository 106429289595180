const INITIAL_STATE = {
  users: [],
  usersLoaded: false
};

const applySetUsers = (state, action) => ({
  ...state,
  users: action.users,
  usersLoaded: true,
});

const applySetUser = (state, action) => ({
  ...state,
  users: [
    ...state.users,
    action.user,
  ],
});

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USERS_SET': {
      return applySetUsers(state, action);
    }
    case 'USER_SET': {
      return applySetUser(state, action);
    }
    default:
      return state;
  }
}

export default userReducer;
