import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { withAuthorization, withEmailVerification, isValidUser } from '../Session';
import UserDetailsForm from './UserDetailsForm';
import PasswordChangeForm from '../PasswordChange';

class AccountPage extends Component {
  render() {
    const { authUser } = this.props;

    return (
      <Paper>
        <h2>Account: {authUser.username}</h2>
        <Box m={2} p={2}>
          <UserDetailsForm authUser={authUser} />
        </Box>
        <Box m={2} p={2}>
          <PasswordChangeForm />
        </Box>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(isValidUser),
)(AccountPage);
